import styled from "@emotion/styled";
import React from "react";

export default function Vision() {
    return (
        <Container>
            <div className="three-of-strategy">
                <div className="strategy-grid">
                    <div className="number">1</div>
                    <h2 className="title">外観、機能デザイン</h2>
                    <div itemProp="text" className="content">
                        <p>着心地よく無理なく動ける。<br/>
                            そして美しく、凛と、誇らしくそう思える製品を目指しています。</p>
                    </div>
                </div>
                <div className="strategy-grid">
                    <div className="number">2</div>
                    <h2 className="title">触感デザイン</h2>
                    <div itemProp="text" className="content">
                        <p>触れるを科学する。<br/>
                            動作や精神的影響を科学的に分析して、<br/>
                            より深い体験と心地よさを提供することを目指しています。</p>
                    </div>
                </div>
                <div className="strategy-grid">
                    <div className="number">3</div>
                    <h2 className="title">環境デザイン</h2>
                    <div itemProp="text" className="content">
                        <p>作り手、使い手の環境を<br/>
                            ここちよいものに</p>
                        <ul className="list">
                            <li>作り手にとって価値のあるもの</li>
                            <li>使い手にとって価値のあるもの</li>
                            <li>買うという行為が価値のあるものに</li>
                        </ul>
                        <p>福祉現場で役立つ製品づくり、貧困、労働環境、産業などKNITOLOGYはニットを通して社会的問題を少しでも解決していくことを使命としています。</p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

const Container = styled.section`

  text-align: center;
  line-height: 3em;

  .three-of-strategy {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ececec;

    .strategy-grid {
      max-width: 50%;
      flex-basis: 50%;
      padding: 32px 40px 48px;
      
      @media (max-width: 768px) {
        max-width: 100%;
        flex-basis: 100%;
        padding: 32px 20px 48px;
      }


      &:first-of-type {
        max-width: 100%;
        flex-basis: 100%;
        border-bottom: 1px solid #ececec;
      }

      &:last-child {
        border-left: 1px solid #ececec;
        
        @media (max-width: 768px) {
          border-left: none;
          border-top: 1px solid #ececec;
        }
      }

      .title {
        margin: 20px auto;
        font-size: 2rem;
        font-size: min(2rem, 8vw);
      }

      p{
        margin-top: 16px;
        font-size: 14px;
        font-size: min(14px, 4vw);
        line-height: 2.8em;
      }
    }

    .list {
      li {
        font-size: 14px;
        font-size: min(14px, 4vw);
        line-height: 2.8em;
      }
    }

    .number {
      width: 64px;
      height: 64px;
      background: #222;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0;

      @media (max-width: 1200px) {
        font-size: calc(1.275rem + .3vw)
      }
    }
  }
`;