import styled from "@emotion/styled";
import { Link } from "gatsby";
import React from "react";

interface BorderButtonProps {
  title: string;
  to: string;
}

export default function BorderButton(props: BorderButtonProps) {
  return (
    <Link to={props.to}>
      <Button>{props.title}</Button>
    </Link>
  );
}

const Button = styled.button`
  padding: 24px 64px;
  color: white;
  //border: 1px solid gray;
  border-radius: 20px;
  background-color: rgb(60, 60, 60);
  padding: 18px 48px;
  cursor: pointer;
  //font-family: YuMincho;
  letter-spacing: 0.2em;

  &:hover {
    background: #9B8750;
    color: #fff;
  }

  @media (min-width: 769px) {
    padding: 24px 64px;
  }
`;
