import styled from "@emotion/styled";
import React from "react";
import BorderButton from "../../common/BorderButton";
import SectionTitle from "../../common/SectionTitle";
import NormalText from "../../common/NormalText";
import BoarderSectionTitle from "../../common/BoarderSectionTitle";

export default function ContactLink() {
    return (
        <Container>
            <div className="wrap">
                <BoarderSectionTitle title="Contact"></BoarderSectionTitle>
                <div className="message">
                    <NormalText>ご不明点やご質問がある方は、<br className="hidePC"/> お気楽にお問い合わせください。</NormalText>
                </div>
                <BorderButton title="お問い合わせ" to="/contact"></BorderButton>
            </div>
        </Container>
    );
}

const Container = styled.div`
  width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  background-color: black;

  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    flex-direction: column;
    
    .message{
      margin-bottom: 66px;
    }
  }

`;