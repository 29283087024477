import styled from "@emotion/styled";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import BoarderSectionTitle from "../components/common/BoarderSectionTitle";
import Layout from "../components/layout";
import ContactLink from "../components/pages/home/ContactLink";
import ShopInfoList from "../components/pages/about/ShopInfoList";
import VaseImage from "../components/pages/home/VaseImage";
import Vision from "../components/pages/about/Vision";
import BorderButton from "../components/common/BorderButton";
import AshikagaButton from "../components/common/AshikagaButton";

export default function AboutPage() {
    return (
        <Layout>

            <Container>
                <ProfileWrap>
                <p className="designer_desc">
                    <a href="https://ashikaga-action.com">鬼久保綾子の足利での取り組みは「足利アクションホームページ」</a> をご覧ください。
                </p>
                <AshikagaButton title="足利アクションホームページ" to="https://ashikaga-action.com"></AshikagaButton>
                </ProfileWrap>
            </Container>
            <VaseImage></VaseImage>
            <Container>
                <ProfileWrap>
                    <h3 className="designer_title">Designer</h3>
                    <p className="jp_name">鬼久保綾子</p>
                    <p className="en_name">Ayako Onikubo</p>
                    <p className="designer_desc">
                        日本のドレスメーカー学院を卒業後、イギリスのノッティンガムトレント大学のニット科に進学。在学中ニットテキスタイルデザイン会社で勤務。福祉デザインを経験したのち、帰国後は慶應義塾大学政策・メディア研究科にて皮膚感覚についての研究を行う。2011年に今まで蓄積してきたニットの技術や知見を活かしたブランド、KNITOLOGYをスタート。福祉現場での活用やアパレル生産環境を改善できるような、体系構築を探りながらニットブランドの可能性を追求し続けている。
                    </p>
                    <StaticImage
                        src="../assets/img/about/onikubo.jpg"
                        alt="ayako onikubo"
                        quality={100}
                    />
                </ProfileWrap>
                <div className="bl-company section">
                    <BoarderSectionTitle title="COMPANY"></BoarderSectionTitle>
                    <div className="company_desc">
                        <div className="company_table_head">
                            <p>企業名</p>
                            <p>代表</p>
                            <p>東京事務所</p>
                            <p>足利事務所</p>
                            <p>電話番号</p>
                        </div>
                        <div className="company_table_content">
                            <p>株式会社KNITLOGY</p>
                            <p>鬼久保 綾子</p>
                            <p>東京都台東区上野1丁目3-7</p>
                            <p>栃木県足利市通3丁目2763</p>
                            <p>03-6721-0861</p>
                        </div>
                    </div>

                </div>
                <div className="bl-factory section">
                    <BoarderSectionTitle title="FACTORY"/>
                    <StaticImage
                        src="../assets/img/about/factory.jpg"
                        alt="factory"
                        quality={100}
                    >
                    </StaticImage>
                    <h4 className="factory-title">東北のニット生産工場</h4>
                    <p className="factory-desc">
                        KNITOLOGYのブランドは生産してくださっている阿部ニットとの出会いがあったからこそ実現しました。
                        阿部ニットはニット産業の盛んな福島にあります。
                        ３.１１の震災の後、混沌としている状況下の中、デザイナーの故郷の福島の地場産業、
                        日本のアパレル産業に少しでも貢献したい、と工場を回っている時に出会い、
                        KNITOLOGYの方向性に共感してくださったのが阿部ニットです。
                        そこから、二人三脚で製品作りを一から支えてくださっています。
                    </p>
                </div>

                <div className="bl-vision section">
                    <BoarderSectionTitle title="Vision"/>
                    <h4 className="vision-title">「 ここちいい 」を３面からデザインしていく</h4>
                    <Vision/>
                </div>

                <div className="bl-stockist section">
                    <BoarderSectionTitle title="STORE"></BoarderSectionTitle>
                    <div className="bl-stockist-wrap">
                        <ShopInfoList></ShopInfoList>
                    </div>
                </div>

                {/*<div className="bl-vision">*/}
                {/*  <BoarderSectionTitle title="VISION"></BoarderSectionTitle>*/}
                {/*</div>*/}
                <ContactLink></ContactLink>
            </Container>
        </Layout>
    );
}

const Container = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 0px 24px 240px;
  background-color: black;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: #fff;

  .section {
    margin-bottom: 120px;
  }

  .bl-company {
    margin-top: 100px;
    color: #aba7a3;

    p {
      font-size: 18px;
      letter-spacing: 0.1em;
      line-height: 28px;
      color: #aba7a3;

      @media (max-width: 768px) {
        font-size: 4vw;
      }
    }


    .company_desc {
      display: flex;
      justify-content: center;
      align-items: center;
      /* width: 50%; */
      margin-left: auto;
      margin-right: auto;
      column-gap: 57px;

      @media (max-width: 768px) {
        column-gap: 5vw;
      }

    }

    .company_table_head {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      p {
        //&:nth-of-type(4) {
        //  margin-top: 25px;
        //}
      }
    }

    .company_table_content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      /* margin-left: 57px; */
    }
  }

  .bl-factory {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    .factory-title {
      margin-top: 20px;
      font-weight: 500;
      font-size: 30px;
      font-size: min(30px, 8vw);
      text-align: center;
      color: #fff;
    }

    .factory-desc {
      max-width: 700px;
      font-weight: 500;
      font-size: 18px;
      font-size: min(18px, 4vw);
      letter-spacing: 0.1em;
      line-height: 2.25;
      text-align: left;
      color: #ccc;

    }

  }

  .bl-vision {
    margin-top: 100px;
    
    .vision-title {
      margin-top: 20px;
      font-weight: 500;
      font-size: 30px;
      text-align: center;
      color: #fff;

      @media (max-width: 768px) {
        font-size: 4vw;
      }
      
    }
  }

  .bl-stockist-wrap {
    margin: 0 auto 160px;
    max-width: 990px;
  }

`;

const ProfileWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 560px;
  margin: 100px auto 0;
  text-align: center;

  .designer_title {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 16px;
    text-align: center;
    color: #aba7a3;
  }

  .jp_name {
    margin-top: 24px;
    font-weight: 500;
    font-size: 28px;
    font-size: min(28px, 6vw);
    letter-spacing: 0.6em;
    line-height: 1em;
    text-align: center;
    color: #aba7a3;
  }

  .en_name {
    margin-top: 18px;
    font-weight: 500;
    font-size: 16px;
    font-size: min(16px, 3.5vw);
    letter-spacing: 0.8em;
    line-height: 26px;
    text-align: center;
    color: #707070;
  }

  .designer_desc {
    margin-top: 36px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 18px;
    font-size: min(18px, 3.5vw);
    letter-spacing: 0.05em;
    line-height: 2.15em;
    text-align: left;
    color: #ccc;
  }

`;
