import styled from "@emotion/styled";
import { Link } from "gatsby";
import React from "react";

interface AshikagaButtonProps {
  title: string;
  to: string;
}

export default function AshikagaButton(props: AshikagaButtonProps) {
  return (
    <Link to={props.to}>
      <Button>{props.title}</Button>
      </Link>
  );
}

const Button = styled.button`
  color: white;
  //border: 1px solid gray;
  border-radius: 20px;
  background-color: #9C1F1E;
  padding: 18px 48px;
  cursor: pointer;
  //font-family: YuMincho;
  letter-spacing: 0.2em;

  &:hover {
    background: #CF2928;
    color: #fff;
  }

  @media (min-width: 769px) {
    padding: 24px 64px;
  }
`;
