import styled from "@emotion/styled";
import React from "react";
import { useInView } from "react-intersection-observer";
import img from "../../../assets/img/index/pc1-3.jpg";
export default function VaseImage() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <Container>
      <ImageWrap ref={ref}>
        <ContentWrap>
          <TextWrap>
            <p>込む</p>
          </TextWrap>
          <TextWrap>
            <p>つつみ</p>
          </TextWrap>
          <TextWrap>
            <p><ruby>儘<rt>まま</rt></ruby>を</p>
          </TextWrap>
          <TextWrap>

            <p><ruby>在<rt>あ</rt></ruby>るが</p>
          </TextWrap>
        </ContentWrap>
      </ImageWrap>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 300px;
`;

const ImageWrap = styled.div`
  background-image: url(${img});
  background-position-x: center;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  //min-height: 722px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (min-width: 769px) {
    width: 100%;
    height: 768px;

    img {
      width: 100%;
      height: auto;
      object-fit: inherit;
    }
  }
`;

const TextWrap = styled.div`
  /* transform: rotate(90deg); */
  position: relative;
  width: 100%;
  height: 100%;
  p {
    display: inline-block;
    position: relative;
    font-size: 40px;
    color: ${(props) => props.theme.gold};
    //font-family: "游明朝", "Yu Mincho", "YuMincho", serif;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 10.8px;
    writing-mode: vertical-lr;

    &:hover {
      --d: 100%;
    }
    
    rt{
      font-size: 14px;
    }
  }
  span {
    content: " ";
    display: inline-block;
    position: absolute;
    right: 0;
    width: inherit;
    height: inherit;
  }

  span::before {
    content: "";
    position: absolute;
    top: 0;
    right: 20%;
    width: 1px;
    height: 90%;
    background: ${(props) => props.theme.gold};
    transform: scale(0);
    @media (min-width: 769px) {
      right: 45%;
    }
  }

`;

const ContentWrap = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 244px;
  width: 100%;
  @media (min-width: 769px) {
    max-width: 377px;
    width: 100%;
  }
`;
